<template>
  <div class="lottery-history">
    <PageTitle title="獎勵發放紀錄" hideBtn />
    <!-- 區間選擇 -->
    <section class="filter-option-wrapper">
      <FiltersContainer>
        <BaseSearch
          :value.sync="formData.memberGame"
          testName="search_rewardDistribution"
          :api="GetRewardDistribution"
          collapse-tags
          placeholder="選擇獎勵發放"
          @change="refresh"
        />

        <MemberSearch
          :model.sync="formData.member"
          testName="search_member"
          @change="refresh"
          @clear="refresh(true)"
        />
      </FiltersContainer>

      <div class="filter-option-container">
        <GroupTimeFilterButton
          testName="filter_dateRange"
          :isCustomRange.sync="customerRange"
          @month="getMonthRange"
          @week="getWeekRange"
          @today="getTodayRange"
          @custom="customerRange = true"
        />

        <el-date-picker
          v-if="customerRange"
          v-model="searchTime"
          editable
          type="datetimerange"
          range-separator="至"
          start-placeholder="開始日期"
          end-placeholder="結束日期"
          format="yyyy-MM-dd HH:mm"
          :default-time="['00:00:00', '23:59:59']"
          @change="changeToCustomer()"
        />
      </div>
    </section>
    <!-- 統計大字 -->
    <section>
      <div class="statistic-header">
        <div v-if="searchTime" class="range-text">
          |選取期間 {{ dateFormat(searchTime[0]) }} ~
          {{ dateFormat(searchTime[1]) }}
        </div>
        <!-- <BaseElButton type="primary" @click="dialog.export = true">匯出名單</BaseElButton> -->
      </div>

      <div class="statistic-container">
        <div>
          <p class="title">活動期間（天）</p>
          <p class="value">
            {{ statistical && statistical.day ? statistical.day : '-' }}
          </p>
        </div>
        <el-divider direction="vertical" />
        <div>
          <p class="title">獎項抽出（個）</p>
          <p class="value">
            {{
              statistical && statistical.recordCount !== 0
                ? statistical.recordCount
                : '-'
            }}
          </p>
        </div>
        <el-divider style="height:100%" direction="vertical" />
        <div>
          <p class="title">不重複參與人數（人）</p>
          <p class="value">
            {{
              statistical && statistical.memberCount
                ? statistical.memberCount
                : '-'
            }}
          </p>
        </div>
      </div>
    </section>

    <!-- Table -->
    <BaseTable v-loading="loading.table" :data="tableData" testName="rewardDistributionRecord">
      <EmptyBlock slot="empty" />
      <BaseElTableColumn label="獎勵發放名稱" prop="RewardDistribution.name" align="center" />
      <BaseElTableColumn label="獎項名稱" prop="RewardDistribution.awardName" align="center" />
      <BaseElTableColumn
        label="會員"
        prop="Member.UserInfo.name"
        align="center"
      />
      <BaseElTableColumn label="發放時間" align="center">
        <template slot-scope="scope">
          {{ dateFormat(scope.row.createdAt) }}
        </template>
      </BaseElTableColumn>
    </BaseTable>

    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh"
    />

    <ExportOptionsDialog
      v-if="dialog.export"
      allRange
      @close="dialog.export = false"
      @export="prepareExport()"
    />
    <!-- <ExportDialog
      v-if="showExportDialog"
      :inProgress="exportting"
      :isError="exportError"
      :percentage="exportPercentage"
      :data="exportData"
      :total="exportCount"
      @close="resetExport"
    /> -->
    <ExportDialog
      v-if="exportState.modal"
      :inProgress="exportState.exportting"
      :isError="exportState.error"
      :content="exportState.content"
      :percentage="totalPercentege"
      @close="onExportClose"
    />
  </div>
</template>

<script>
import ExportOptionsDialog from '@/components/Dialog/ExportOptionsDialog.vue'
import ExportDialog from '@/components/Dialog/ExportDialog.vue'
import { checkUserFeature } from '@/store/modules/permission'
import { ExportExcel } from '@/utils/excel'
import {
  GetRewardDistribution,
  GetRewardDistributionLinkUseRecord,
  GetRewardDistributionLinkUseRecordCount,
  GetRewardDistributionLinkUseRecordStatistical,
} from '@/api/rewardDistribution'

import dayjs from '@/lib/dayjs'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import GroupTimeFilterButton from '@/components/Input/GroupTimeFilterButton.vue'
import { defineComponent, onMounted, ref, reactive, computed, set } from 'vue'
import { get, flatten } from 'lodash'
import store from '@/store'
import { useTable } from '@/use/table'
import { useExport } from '@/use/export'
import BaseSearch from '@/components/Search/BaseSearch.vue'
import MemberSearch from '@/components/Search/MemberSearch.vue'

export default defineComponent({
  name: 'RewardDistributionSettingRecord',
  components: {
    GroupTimeFilterButton,
    ExportDialog,
    ExportOptionsDialog,
    BaseSearch,
    MemberSearch,
  },
  setup () {
    const {
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      shopId,
      fetchData,
      fetchDataCount,
      dateFormat,
    } = useTable()

    const { exportState, resetExport, getExportData, totalDataCount, totalPercentege } = useExport()
    const userPlanFeature = computed(() => get(store.getters, 'userPlanFeature'))
    const userFeatures = computed(() => get(store.getters, 'userFeatures'))
    const searchTime = ref([])
    const showExportOptions = ref(false)
    const showExportDialog = ref(false)
    const timeRangeType = ref('today')
    const customerRange = ref(false)
    const statistical = ref(null)
    const formData = reactive({
      member: null,
      memberGame: '',
      rewardName: '',
    })
    const dialog = reactive({
      export: false,
    })
    const exportExcel = async () => {
      const payload = {
        shopId: shopId.value,
        start: 0,
        createdAtStart: get(searchTime.value, '[0]'),
        createdAtEnd: get(searchTime.value, '[1]'),
        RewardDistributionId: get(formData.memberGame, 'id'),
        MemberId: get(formData.member, 'id'),
      }
      exportState.modal = true
      exportState.exportting = true
      try {
        const [res, err] = await GetRewardDistributionLinkUseRecordCount(payload)
        exportState.dataCount = res
        if (!totalDataCount.value) {
          exportState.content = '尚無資料可匯出'
          exportState.error = true
          window.$message.warning('尚無資料可匯出')
          return
        }
        if (err) return window.$message.error(err)
        await getExportData({ stage: 0, fetchAPI: GetRewardDistributionLinkUseRecord, payload, throwErr: true })
        const formatData = formateExportData(exportState.exportData)
        exportState.exportting = false
        exportState.title = '匯出成功'
        ExportExcel(formatData, '抽獎結果', `抽獎結果 ${dateFormat(get(searchTime.value, '[0]'))} ~ ${dateFormat(
          get(searchTime.value, '[1]'),
        )}`)
      } catch (error) {
        exportState.error = true
        exportState.exportting = false
        window.$message.error(error)
      }
    }
    const formateExportData = (data) => {
      data = flatten(data)
      console.log(data)
      const sheetsData = []
      data.forEach((item) => {
        const row = {
          抽獎名稱: get(item, 'MemberGame.name'),
          抽獎類型: get(item, 'MemberGame.MemberGameMode.name'),
          獎項名稱: get(item, 'awardName'),
          會員: get(item, 'Member.UserInfo.name'),
          抽獎時間: dateFormat(item.createdAt),
        }

        sheetsData.push(row)
      })
      console.log(sheetsData)
      return sheetsData
    }
    const prepareExport = async () => {
      const permission = checkUserFeature(userPlanFeature.value, userFeatures.value, 'admin.member.exportExcel')
      if (!permission) return window.$message.error('無此權限 admin.member.exportExcel')
      dialog.export = false
      await exportExcel()
    }
    const onExportClose = () => {
      resetExport()
    }
    const refresh = async () => {
      loading.table = true
      await getTableData()
      loading.table = false
    }
    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        createdAtStart: get(searchTime.value, '[0]'),
        createdAtEnd: get(searchTime.value, '[1]'),
        RewardDistributionId: get(formData.memberGame, 'id'),
        MemberId: get(formData.member, 'id'),
      }
      await Promise.allSettled([
        fetchData(GetRewardDistributionLinkUseRecord, payload),
        fetchDataCount(GetRewardDistributionLinkUseRecordCount, payload),
        memberGameRecordStatistical(payload),
      ])
    }
    const isActived = (type) => {
      if (timeRangeType.value === type && !customerRange.value) return 'primary'
      return ''
    }
    const getTodayRange = async () => {
      const start = startOfDay(new Date())
      const end = endOfDay(new Date())
      set(searchTime.value, '[0]', start)
      set(searchTime.value, '[1]', end)
      await refresh()
    }
    const getWeekRange = async () => {
      const start = startOfWeek(new Date())
      const end = endOfWeek(new Date())
      set(searchTime.value, '[0]', start)
      set(searchTime.value, '[1]', end)
      await refresh()
    }
    const getMonthRange = async () => {
      const start = startOfMonth(new Date())
      const end = endOfMonth(new Date())
      set(searchTime.value, '[0]', start)
      set(searchTime.value, '[1]', end)
      await refresh()
    }
    const changeToCustomer = async () => {
      customerRange.value = true
      const start = dayjs(get(searchTime.value, '[0]'))
      const end = dayjs(get(searchTime.value, '[1]'))
      if (start.hour() !== 0 || start.minute() !== 0) {
        customerRange.value = true
      } else if (end.hour() !== 23 || end.minute() !== 59) {
        customerRange.value = true
      }
      await refresh()
    }
    const changeTimeRangeType = async (type) => {
      if (type === 'today') {
        getTodayRange()
      } else if (type === 'week') {
        getWeekRange()
      } else if (type === 'month') {
        getMonthRange()
      }

      timeRangeType.value = type
      customerRange.value = false
      await refresh()
    }
    const memberGameRecordStatistical = async (payload) => {
      const [res, err] = await GetRewardDistributionLinkUseRecordStatistical(payload)
      console.log(err)
      statistical.value = res
    }
    onMounted(async () => {
      getTodayRange()
      await refresh()
    })

    return {
      tableData,
      tableDataCount,
      searchTime,
      exportExcel,
      prepareExport,
      resetExport,
      refresh,
      isActived,
      getTodayRange,
      getWeekRange,
      getMonthRange,
      changeToCustomer,
      changeTimeRangeType,
      memberGameRecordStatistical,
      tableOptions,
      showExportOptions,
      showExportDialog,
      dateFormat,
      formData,
      customerRange,
      statistical,
      loading,
      onExportClose,
      totalPercentege,
      exportState,
      dialog,
      GetRewardDistribution,
    }
  },
})
</script>

<style lang="scss" scoped>
::v-deep .date-range-btn {
  @apply shadow-none;
}
.el-divider--vertical {
  @apply h-auto w-[2px];
}
.filter-option-wrapper {
  @apply flex flex-col gap-[16px];
  .filter-option-container {
    @apply flex gap-[12px] flex-wrap;
  }
}

.statistic-container {
  @apply flex justify-evenly text-center py-[12px];
  @apply bg-white rounded-lg rounded-br-sub;
  box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.1);

  .title {
    @apply text-gray-100 text-sub;
  }

  .value {
    @apply text-secondary-100 text-[28px] font-bold;
  }
}

.statistic-header {
  @apply flex justify-between items-end pb-[16px];
}
.range-text {
  @apply text-primary-100 font-medium;
}

.date-range-btn {
  @apply px-[28px];
}
</style>
